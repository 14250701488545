import { createBrowserClient } from '@supabase/ssr';

export const createClient = () =>
  createBrowserClient(
    // eslint-disable-next-line no-undef
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    // eslint-disable-next-line no-undef
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    {
      db: {
        schema: 'public',
      },
      cookieOptions: {
        name: 'sb_token',
      },
    },
  );

///////////////////////////////////////////////////
// Logica de real-time (suscripciones), no la uso actualmente
///////////////////////////////////////////////////

const subscriptions = new Map();
export const fetchAndSubscribe = async (
  table,
  fields,
  callback,
  events = ['INSERT', 'UPDATE', 'DELETE'],

  schema = 'public',
) => {
  const supabase = createClient();

  // Fetch initial data (all fields plus id)
  const fieldsAsArray = fields.split(',').map((field) => field.trim());
  const fieldsPlusId = [...fieldsAsArray, 'id'];
  const fieldsToFetch = fieldsPlusId.join(', ');
  const { data: initialData, error } = await supabase
    .from(table)
    .select(fieldsToFetch);

  if (error) {
    console.error('Error fetching data:', error);
    return { data: null, error, subscription: null };
  }

  let currentData = [...initialData];

  // Subscribe to changes
  const channelName = `${table}_changes`;
  const subscription = subscribeToChannel(
    channelName,
    table,
    (payload) => {
      const { eventType, new: newRecord, old: oldRecord } = payload;

      switch (eventType) {
        case 'INSERT':
          currentData.push(newRecord);
          break;
        case 'UPDATE':
          // eslint-disable-next-line no-case-declarations
          const updateIndex = currentData.findIndex(
            (item) => item.id === newRecord.id,
          );
          if (updateIndex !== -1) {
            currentData[updateIndex] = {
              ...currentData[updateIndex],
              ...newRecord,
            };
          }
          break;
        case 'DELETE':
          currentData = currentData.filter((item) => item.id !== oldRecord.id);
          break;
      }

      callback(currentData, payload);
    },
    events,
    schema,
  );

  return { data: currentData, error: null, subscription };
};

const subscribeToChannel = (channelName, table, callback, events, schema) => {
  const supabase = createClient();

  if (subscriptions.has(channelName)) {
    console.warn(
      `A subscription to ${channelName} already exists. Removing the existing subscription.`,
    );
    removeSubscription(channelName);
  }

  let channel = supabase.channel(channelName);

  events.forEach((event) => {
    channel = channel.on(
      'postgres_changes',
      { event: event, schema: schema, table: table },
      (payload) => callback(payload),
    );
  });

  const subscription = channel.subscribe();

  subscriptions.set(channelName, subscription);

  return subscription;
};

export const removeSubscription = (channelName) => {
  const subscription = subscriptions.get(channelName);
  if (subscription) {
    subscription.unsubscribe();
    subscriptions.delete(channelName);
    return true;
  }
  return false;
};

export const removeAllSubscriptions = () => {
  subscriptions.forEach((subscription, channelName) => {
    subscription.unsubscribe();
  });
  subscriptions.clear();
};
