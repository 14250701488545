import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { createClient } from '@/services/supabase/client';
import { getQuotaStatus, checkAndResetQuota } from '@/services/quotaBackend';
import { flatPermissions } from '@/services/tools';
import { User } from '@supabase/supabase-js';

const defaultLanguage = '';

interface Quota {
  used?: number;
  lastReset?: string;
  dailyLimit?: number | undefined;
  enabled?: boolean;
}
interface Company {
  id?: number;
  rut?: string;
  nombre?: string;
  logo?: string;
  placesId?: string;
  google_url?: string;
  quota?: Quota;
  idioma?: string;
}

/**
 * Interfaz que define la estructura y funcionalidades del contexto
 * @interface EntiContextType
 */
interface EntiContextType {
  /** Usuario actual */
  user: User | null;
  /** Función para establecer el usuario */
  setUser: (user: User) => void;
  /** Idioma actual */
  lang: string;
  /** Función para establecer el idioma */
  setLang: (lang: string) => void;
  /** Función para establecer la quota */
  setQuota: (quota: Quota) => void;
  /** Función para verificar permisos */
  canI: (permissionAlternatives: string[]) => boolean;
  /** Lista de empresas */
  companies: Company[];
  /** Función para establecer la lista de empresas */
  setCompanies: (companies: Company[]) => void;
  /** Lista de permisos del usuario actual */
  myPermissions: string[];
  /** Empresa activa actualmente */
  activeCompany: Company;
  /** Función para establecer la empresa activa por ID */
  setActiveCompanyById: (id: number, companyList?: Company[]) => void;
  /** Función para obtener una empresa por ID */
  getCompany: (id: number, companyList?: Company[]) => Company | undefined;
  /** Quota actual de la empresa activa */
  quota: Quota | null;
  /** Tiempo restante en milisegundos hasta el próximo reset */
  quotaTimeLeft: number;
  refreshQuota: () => Promise<void>;
}

// Creación del contexto con tipo
const EntiContext = createContext<EntiContextType | null>(null);

/**
 * Props para el componente EntiProvider
 * @interface EntiProviderProps
 */
interface EntiProviderProps {
  /** Elementos hijos del proveedor */
  children: React.ReactNode;
}

/**
 * Componente proveedor que encapsula toda la lógica del contexto
 * @component
 * @param {EntiProviderProps} props - Props del componente
 */
export function EntiProvider({ children }: EntiProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [lang, setLang] = useState<string>(defaultLanguage);
  const [quota, setQuota] = useState<Quota | null>(null);
  const [quotaTimeLeft, setQuotaTimeLeft] = useState<number>(0);
  const quotaInterval = useRef<NodeJS.Timeout>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [myPermissions, setMyPermissions] = useState<string[]>([]);
  const [activeCompany, setActiveCompany] = useState<Company>({});
  const supabase = createClient();
  const init = useRef(false);

  /**
   * Verifica si el usuario tiene alguno de los permisos especificados
   * @param {string[]} permissionAlternatives - Array de permisos a verificar
   * @returns {boolean} - Verdadero si tiene alguno de los permisos
   */
  function canI(permissionAlternatives: string[] = []): boolean {
    return myPermissions.some((p) => permissionAlternatives?.includes?.(p));
  }

  /**
   * Obtiene una empresa por su ID
   * @param {number} id - ID de la empresa a buscar
   * @param {Company[]} [companyList] - Lista opcional de empresas donde buscar
   * @returns {Company | undefined} - La empresa encontrada o undefined
   */
  function getCompany(
    id: number,
    companyList?: Company[],
  ): Company | undefined {
    const source = companyList || companies;
    return source.find((c) => c.id === id);
  }

  /**
   * Establece la empresa activa por su ID
   * @param {number} id - ID de la empresa a activar
   * @param {Company[] | null} [companyList=null] - Lista opcional de empresas donde buscar
   */
  const setActiveCompanyById = (
    id: number,
    companyList: Company[] | null = null,
  ) => {
    const company = getCompany(id, companyList || undefined);
    if (company) {
      setActiveCompany(company);
      setQuota(company.quota || null);
      localStorage.setItem('activeCompanyId', id.toString());
      document.cookie = `activeCompanyId=${id}`;
    }
  };

  /**
   * Función para verificar si es necesario reiniciar la quota
   */

  function checkQuotaReset(quota: Quota): {
    shouldReset: boolean;
    timeLeft: number;
  } {
    const lastReset = new Date(quota.lastReset || '');
    const now = new Date();
    const timeSinceReset = now.getTime() - lastReset.getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000;

    return {
      shouldReset: timeSinceReset >= twentyFourHours,
      timeLeft: Math.max(0, twentyFourHours - timeSinceReset),
    };
  }

  const updateQuotaTimeLeft = () => {
    if (quota) {
      const { timeLeft } = checkQuotaReset(quota);
      setQuotaTimeLeft(timeLeft);
      if (timeLeft === 0) {
        checkAndResetQuota(activeCompany?.id).then(() => refreshQuota(true));
      }
    }
  };

  const refreshQuota = async (noUpdate = false) => {
    if (!activeCompany?.id) return;

    try {
      const newQuota = await getQuotaStatus(activeCompany.id);
      if (newQuota) {
        console.log(`status de quota difiere, actualizo quota`, newQuota);
        setQuota(newQuota);
        if (!noUpdate) updateQuotaTimeLeft();
      }
    } catch (error) {
      console.error('Error al actualizar quota:', error);
    }
  };

  // Effect para la actualización periódica del tiempo restante
  useEffect(() => {
    if (quota) {
      updateQuotaTimeLeft();
      if (quotaInterval.current) {
        clearInterval(quotaInterval.current);
      }
      quotaInterval.current = setInterval(updateQuotaTimeLeft, 4000);
    }
    return () => {
      if (quotaInterval.current) {
        clearInterval(quotaInterval.current);
      }
    };
  }, [quota]);

  // Effect para inicialización
  useEffect(() => {
    if (init.current) return;
    init.current = true;

    supabase
      .from('empresas')
      .select('*')
      .then(({ data }) => {
        if (data) {
          setCompanies(data as any[]);
          const activeCompanyId = localStorage.getItem('activeCompanyId');
          if (activeCompanyId && data.find((d) => d.id === +activeCompanyId)) {
            setActiveCompanyById(+activeCompanyId, data as any[]);
          } else {
            //seleccionar la primera empresa disponible para el usuario como activa
            setActiveCompanyById(data[0]?.id, data as any[]);
            localStorage.setItem('activeCompanyId', data[0]?.id.toString());
            document.cookie = `activeCompanyId=${data[0]?.id}`;
          }
        }
      });

    (async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    })();
  }, [supabase]);

  // Effect para actualizar permisos
  useEffect(() => {
    if (!activeCompany?.id || !user?.id) return;
    setMyPermissions(flatPermissions(user, activeCompany.id));
  }, [activeCompany, user]);

  // Effect para determinar idioma al bootear si hay cookie de activeCompanyId
  useEffect(() => {
    //omitir si hay un login activo, ya que cuando termine de cargar se activará su idioma
    const userToken = document.cookie.match(/sb_token=base64/)?.[1];
    if (userToken) return;

    //elegir el mejor idioma disponible
    const cookieLanguage = document.cookie.match(/NEXT_LOCALE=([a-z]{2})/)?.[1];
    const browserLanguage = navigator.language.match(/es/) ? 'es' : 'en';
    setLang(cookieLanguage || browserLanguage);
  }, []);

  // Effect para cambiar idioma posterior al booteo, al cambiar activeCompany
  useEffect(() => {
    if (!activeCompany?.id) return;

    //Determinar idioma
    const cookieLanguage = document.cookie.match(/NEXT_LOCALE=([a-z]{2})/)?.[1];
    const browserLanguage = navigator.language.match(/es/) ? 'es' : 'en';
    const companyLanguage = activeCompany?.idioma;
    const newLang =
      (location?.pathname !== '/' && companyLanguage) ||
      cookieLanguage ||
      browserLanguage;

    setLang(newLang);
    document.cookie = `NEXT_LOCALE=${newLang}`;
  }, [activeCompany]);

  const contextValue: EntiContextType = {
    user,
    setUser,
    lang,
    setLang,
    setQuota,
    canI,
    companies,
    setCompanies,
    myPermissions,
    activeCompany,
    setActiveCompanyById,
    getCompany,
    quota,
    quotaTimeLeft,
    refreshQuota,
  };

  return (
    <EntiContext.Provider value={contextValue}>{children}</EntiContext.Provider>
  );
}

/**
 * Hook personalizado para usar el contexto con tipado
 * @throws {Error} Si se usa fuera del Provider
 * @returns {EntiContextType} El contexto tipado
 */
export function useEntiContext(): EntiContextType {
  const context = useContext(EntiContext);
  if (!context) {
    throw new Error('useEntiContext debe ser usado dentro de un EntiProvider');
  }
  return context;
}
