export function fechaHoy() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Aplana y filtra un objeto de permisos jerárquico en un array único de permisos.
 *
 * @param {Object} permissions - Objeto de permisos con estructura jerárquica
 *                              (ej: {user: [...], companies: {id: [...]}})
 * @param {string|null} companyId - ID de la compañía para filtrar permisos específicos.
 *                                 Si es null, no filtra por compañía.
 * @param {boolean} companyOnly - Si es true, ignora los permisos de usuario y solo
 *                               incluye permisos relacionados con compañías.
 *                               Si es false, incluye permisos de usuario junto con
 *                               los de la compañía específica (si se proporciona companyId).
 *
 * @returns {string[]} Array de permisos únicos
 *
 * @example
 * const perms = {
 *   user: ["god", "admin"],
 *   companies: {
 *     "5": ["god", "ble"]
 *   }
 * };
 *
 * flattenPermissions(perms)                // ["god", "admin", "ble"]
 * flattenPermissions(perms, "5")          // ["god", "admin", "ble"]
 * flattenPermissions(perms, "5", true)    // ["god", "ble"]
 * flattenPermissions(perms, null, true)   // ["god", "ble"]
 */
function flattenPermissions(
  permissions,
  companyId = null,
  companyOnly = false,
) {
  const uniquePermissions = new Set();

  function processValue(value, path = []) {
    if (Array.isArray(value)) {
      const isCompanyPath = path.includes('companies');
      const isUserPath = path.includes('user');

      const shouldProcess =
        (!companyId && !companyOnly) || // Procesa todo si no hay restricciones
        (!companyOnly && isUserPath) || // Procesa user si no es companyOnly
        (isCompanyPath && !companyId && companyOnly) || // Procesa todas las companies si es companyOnly
        (isCompanyPath && companyId && path.includes(companyId)); // Procesa company específica

      if (shouldProcess) {
        value.forEach((item) => uniquePermissions.add(item));
      }
    } else if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([key, val]) => {
        processValue(val, [...path, key]);
      });
    }
  }

  processValue(permissions);
  return Array.from(uniquePermissions);
}

/**
 * Aplana y filtra los permisos de un usuario en un array único de permisos.
 *
 * @param {Object} user - Objeto de usuario con estructura de permisos jerárquica
 * @param {number} companyId - ID de la compañía para filtrar permisos específicos.
 *
 * @returns {string[]} Array de permisos únicos
 */
export function flatPermissions(user, companyId) {
  const permisosUsuario = user?.user_metadata?.permissions?.user || [];
  const permisosEmpresas = user?.user_metadata?.permissions?.companies || [];
  return flattenPermissions(
    {
      user: permisosUsuario,
      companies: permisosEmpresas,
    },
    '' + companyId,
  );
}

export function formatXML(xmlDoc) {
  let formatted = '';
  const nodes = xmlDoc.documentElement.childNodes;

  function processNode(node, level) {
    const indent = '  '.repeat(level);

    switch (node.nodeType) {
      case Node.ELEMENT_NODE:
        formatted += `${indent}<span class="tag">&lt;${node.nodeName}</span>`;

        // Handle attributes
        Array.from(node.attributes).forEach((attr) => {
          formatted += ` <span class="attr">${attr.name}</span>="<span class="value">${attr.value}</span>"`;
        });

        if (node.childNodes.length === 0) {
          formatted += '<span class="tag">/&gt;</span>\n';
        } else {
          formatted += '<span class="tag">&gt;</span>\n';
          Array.from(node.childNodes).forEach((child) =>
            processNode(child, level + 1),
          );
          formatted += `${indent}<span class="tag">&lt;/${node.nodeName}&gt;</span>\n`;
        }
        break;

      case Node.TEXT_NODE:
        const text = node.nodeValue.trim();
        if (text) {
          formatted += `${indent}<span class="text">${text}</span>\n`;
        }
        break;
    }
  }

  processNode(xmlDoc.documentElement, 0);
  return formatted;
}
